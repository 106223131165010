import React, { Component } from 'react';

import Timeline, {TimelineMarkers, TodayMarker} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import './ProgressChart.css'
//import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'

export default class ProgressChart extends Component {


  constructor(props){
    super(props)

    this.state = {
      currentAssignments: [],
      seletedAnalyst: '',
    }

    this.dsMap = {
      ozone:'aoso3',

    }
  }

  componentDidMount(){
    this.setCurrentAssignments()
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevProps.assignments !== this.props.assignments || prevProps.amfCodes !== this.props.amfCodes) {
      this.setCurrentAssignments()
    }
  }


  setCurrentAssignments = () => {
    let assignments = []

    for(let i=0; i<this.props.assignments.length; i++) {
      let assignment = this.props.assignments[i]
      if(assignment.person_id === this.props.currentUser.name){
        let site = assignment.site_code.toLowerCase()

       
        if(site.includes('amf')){
          if (site in this.props.amfCodes){
            site = this.props.amfCodes[site]
          }
        }
        
        assignments.push(site + assignment.instrument_assignment)
      }
    }

    this.setState({
      currentAssignments: assignments
    })
  }

  handleAnalystChange = (e) => {
    this.props.toggleLoadIndicator(true)
    let analystAssignments = []

    for (let i=0; i<this.props.assignments.length; i++) {
      let assignment = this.props.assignments[i]
      if(assignment.person_id === e.target.value){
        //Filter out assignments that are not deployed
        if(assignment.assignment_metadata.deployment_status === "Deployed") {

          let site = assignment.site_code.toLowerCase()
          let inst = assignment.instrument_assignment.toLowerCase()
          if(site.includes('(')){
            site = site.match(/\((.*)\)/)[1]
          }
          analystAssignments.push({
            instrument_assignment: inst,
            site_code: site
          })
        }
      }
    }

    this.setState({
      seletedAnalyst: e.target.value,
      currentAssignments: analystAssignments.map((assignment) => {
        let site = assignment.site_code.toLowerCase()
       
        if(site.includes('amf')){
          if (site in this.props.amfCodes){
            site = this.props.amfCodes[site]
          }
        }
        return site + assignment.instrument_assignment
      })
    });

    let selectedAnalystId = ''
    for (let i=0; i<this.props.analysts.length; i++) {
      let analyst = this.props.analysts[i]
      if(analyst.name === e.target.value){
        selectedAnalystId = analyst.armid
        break
      }
    }


    this.props.getDQAs(selectedAnalystId, analystAssignments, ()=>{
      this.props.toggleLoadIndicator(false)
    })
  }

  render() {
    let groups = []
    let items = []
    for (let ds in this.props.data) {
      // console.log(ds, this.state.currentAssignments)
      if(this.state.currentAssignments.includes(ds)){
        groups.push({
          'id': ds,
          'title': ds
        })
        for (let i=0;i<this.props.data[ds].length;i++) {
          let dqa = this.props.data[ds][i]
          items.push({
            id: dqa.dqano,
            group: ds,
            title: 'DQA ' + String(dqa.dqano),
            start_time: moment(dqa.dqa_startdate).startOf('day').add(1, "days"),
            end_time: moment(dqa.dqa_enddate).startOf('day').add(2, "days"),
            className: 'complete-dqa',
            canMove: false,
            canResize: false,
            stackItems: true
          })
        }
      }
    }

    return(
      this.props.visible ?
        <div className='progresschart-container'>
          <div className="progresschart-select-container">
            <select
              onChange={this.handleAnalystChange}
              value={this.state.seletedAnalyst === '' ? this.props.currentUser.name : this.state.seletedAnalyst}
              className='progresschart-select'
            >
              {
                this.props.analysts.map( (analyst, index)=> {
                  return <option key={index} value={analyst.name}>{analyst.name}</option>
                })
              }
            </select>
          </div>
           <Timeline
              sidebarContent={<div>Instrument<br/>Assignment</div>}
              groups={groups}
              items={items}
              defaultTimeStart={moment().subtract(1, 'month')}
              defaultTimeEnd={moment()}
            >
            <TimelineMarkers>
              <TodayMarker
                style={{backgroundColor: 'red'}}
              />
            </TimelineMarkers>
          </Timeline>
        </div>
      :
      null
    )
  }
}