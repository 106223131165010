import React, { Component } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
library.add(faSpinner)


export default class LoginModal extends Component {

	constructor(props) {
		super(props)

		this.state = {
			username: '',
			password: ''
		}
	}

	handleChange = (e) => {
		let newState = {}
		let value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
    	let name = e.target.name;

    	newState[name] = value
    	this.setState(newState)
	}

	handleKeyPress = (e) => {
		var code = e.keyCode || e.which;
	    if(code === 13) { //13 is the enter keycode
	        this.props.authenticateUser(this.state.username, this.state.password)
	    } 
	}

	render() {
		return(
			<div className='modal-bg-overlay'>
				<div className='modal-login-container'>
					<div className='modal-login-header'>
						<p ><strong>Sign In</strong></p>
					</div>
					<div className='modal-login-body'>
						<input onKeyPress={this.handleKeyPress} name='username' onChange={this.handleChange} type='text' placeholder='Username'/>
						<input onKeyPress={this.handleKeyPress} name='password' onChange={this.handleChange} type='password' placeholder='Password'/>
						<button disabled={this.props.isAuthenticating} className="modal-login-button" onClick={() => this.props.authenticateUser(this.state.username, this.state.password)}>
							
							{ this.props.isAuthenticating ?
								<FontAwesomeIcon className='modal-login-spinner' icon="spinner" size="2x" />
							:
								'Login'
							}
						</button>
						<p style={{color:"red", textAlign:"center"}}>{this.props.message}</p>
						<p><a href='https://adc.arm.gov/armuserreg/#/forgot'>Forgot Username or Password</a></p>
					</div>
				</div>
			</div>
		)
	}
}