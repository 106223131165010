import React, { Component } from 'react';

export default class AddRowModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            site: '',
            instrument: '',
            analyst: ''
        }
    }

    handleChange = (e) => {
        let newState = {}
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        let name = e.target.name;

        newState[name] = value
        this.setState(newState)
    }

    handleKeyPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13) { //13 is the enter keycode
            this.props.addAssignment(this.state.site, this.state.instrument, this.state.analyst)
            this.props.toggleVisibility()
            // this.props.authenticateUser(this.state.username, this.state.password)
        } 
    }

    handleButtonClick = () => {
        this.props.addAssignment(this.state.site, this.state.instrument, this.state.analyst)
        this.props.toggleVisibility()
    }


    handleAnalystChange = (event) => {
        this.setState({
            analyst: event.target.value
        })
    }

    render(){
        return(
            <div style={{position:'fixed', left:0, right:0}} className='modal-bg-overlay'>
                <div className='modal-addrow-container'>
                    <div className='modal-login-body'>
                        <input onKeyPress={this.handleKeyPress} name='site' onChange={this.handleChange} type='text' placeholder='Site'/>
                        <input onKeyPress={this.handleKeyPress} name='instrument' onChange={this.handleChange} type='text' placeholder='Instrument'/>
                        <select onChange={this.handleAnalystChange} className="modal-addrow-select">
                            <option value={''}>{''}</option>
                            {
                              this.props.analysts.map( (analyst, index)=> {
                                return <option key={index} value={analyst.name}>{analyst.name}</option>
                              })
                            }
                        </select>
                        <button className="modal-addrow-button" onClick={this.handleButtonClick}>
                            Submit
                        </button>
                        <button className="modal-addrow-button" onClick={this.props.toggleVisibility}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}