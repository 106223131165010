import React, { Component } from 'react';

export default class AddRowModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            datastreams: this.props.row.original.assignment_metadata.datastreams,
        }
    }

    componentDidMount() {
        console.log(this.props.row.original.assignment_metadata.datastreams)
    }

    handleChange = (e) => {
        let newState = {}
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
        let name = e.target.name;

        newState[name] = value
        this.setState(newState)
    }

    handleKeyPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13) { //13 is the enter keycode
            var dsArray = this.state.datastreams.split(",").map(item => item.trim());
            this.props.updateAssignmentMetadata('datastreams', this.props.row.original.id ,dsArray)
            this.props.toggleVisibility()
        } 
    }

    handleButtonClick = () => {
        var dsArray = this.state.datastreams.split(",").map(item => item.trim());
        this.props.updateAssignmentMetadata('datastreams', this.props.row.original.id, dsArray)
        this.props.toggleVisibility()
    }


    handleAnalystChange = (event) => {
        this.setState({
            analyst: event.target.value
        })
    }

    render(){
        return(
            <div style={{position:'fixed', left:0, right:0}} className='modal-bg-overlay'>
                <div className='modal-addrow-container'>
                    <div className='modal-login-body'>
                        
                        <textarea className="modal-adddatastreams-textarea" defaultValue={this.state.datastreams} onKeyPress={this.handleKeyPress} name='datastreams' onChange={this.handleChange} type='text' placeholder='Enter datastreams separated by commas'>
                        </textarea>
                        
                        <button className="modal-addrow-button" onClick={this.handleButtonClick}>
                            Submit
                        </button>
                        <button className="modal-addrow-button" onClick={this.props.toggleVisibility}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}