import React, { Component } from 'react';

import defaultProfilePic from '../images/Portrait_Placeholder.png'

export default class SidebarProfile extends Component {

	render() {
		return(
			<div className="sidebar-profile-container noselect">
				<img className="sidebar-profile-image" src={defaultProfilePic} alt="profile_picture"/>
				<div className="sidebar-profile-name noselect">
					{this.props.user.name}
				</div>
				<div className="sidebar-profile-role noselect">
					{this.props.user.role}
				</div>
			</div>
		)
	}
	
}