import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';

import './AddConfDatastreamsModal.css';

export default class AddConfDatastreamsModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ds_code:'',
            site:'',
            facility:'',
            level:'',
            processing_enabled: true,
            is_raw_data: false,
            combine_daily_files: true,
            use_slurm: false,
            missing_data_threshold: 48,
            message: '',
            message_color: 'green',
            is_loading: false,
            add_button_text: "Add"
        }
    }

    handleOnInputChange = (event) => {
        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.id]: event.target.checked
            })
        }
        else{
            if (event.target.id === "facility") {
                this.setState({
                    [event.target.id]: event.target.value.toUpperCase()
                })
            }
            else {
                 this.setState({
                    [event.target.id]: event.target.value
                })
            }
            
        }
    }

    handleSubmit = () => {
        this.setState({
            is_loading: true,
            message: "...",
            message_color: "black"
        }, () => {
            if (this.state.ds_code !== '' && (this.state.site !== '' && this.state.facility !== '' && this.state.level !== '')) {

                let ds_conf = {
                    datastream: this.state.site + this.state.ds_code + this.state.facility.toUpperCase() + "." + this.state.level,
                    ds_code: this.state.ds_code,
                    site: this.state.site,
                    facility: this.state.facility,
                    level: this.state.level,
                    is_raw_data: this.state.is_raw_data,
                    missing_data_threshold: this.state.missing_data_threshold,
                    processing_enabled: this.state.processing_enabled,
                    combine_daily_files: this.state.combine_daily_files,
                    use_slurm: this.state.use_slurm
                }

                this.props.addDatastreamConf(ds_conf, (message) => {
                    this.setState({
                        message_color: message === "Success" ? "green" : "red",
                        add_button_text: "Add Another",
                        is_loading: false,
                        message: message === "Success" ? "datastream added successfully" : message
                    })
                })

            }
            else {
                this.setState({
                    message_color: "red",
                    is_loading: false,
                    message: "Error: fields can not be left blank."
                })
            }
        })
       
    }

    render(){
        return(
            <div style={{position:'fixed', left:0, right:0}} className='modal-bg-overlay'>
                <div className='confmodal-addrow-container'>
                    <div className='modal-login-body'>
                        
                        <form>
                          <input onChange={this.handleOnInputChange} type="text" id="ds_code" name="ds_code" placeholder='Datastream Code' value={this.state.ds_code}/>                          
                          <Tooltip
                              title={"Use amf1 or amf2 for AMF deployment sites"}
                              position="right"
                              trigger="mouseenter"
                              arrow="true"
                              duration='50'
                              theme="light"
                              distance={20}
                            >
                            <input onChange={this.handleOnInputChange} type="text" id="site" name="site"  placeholder='Site' value={this.state.site}/>
                          </Tooltip>
                          <input onChange={this.handleOnInputChange} type="text" id="facility" name="facility"  placeholder='Facility' value={this.state.facility}/>
                          <input onChange={this.handleOnInputChange} type="text" id="level" name="level"  placeholder='Level' value={this.state.level}/>
                          <br/><br/>

                          <label for="missing_data_threshold">Data Not Available Threshold (hrs):</label>
                          <input type="number" id="missing_data_threshold" name="missing_data_threshold" min="24" value={this.state.missing_data_threshold} onChange={this.handleOnInputChange}/>

                          
                          <div style={{marginTop: 10}}>
                            <label for="processing_enabled">Processing Enabled:</label>
                            <input onChange={this.handleOnInputChange} className='confmodal-addrow-checkbox' id="processing_enabled" name="processing_enabled" type="checkbox" checked={this.state.processing_enabled}/><br/>
                          </div>
                         <div style={{marginTop: 10}}>
                            <label for="is_raw_data">Raw Data:</label>
                            <input onChange={this.handleOnInputChange} className='confmodal-addrow-checkbox' id="is_raw_data" name="is_raw_data" type="checkbox" checked={this.state.is_raw_data}/><br/>
                          </div>

                          <div style={{marginTop: 10}}>
                            <label for="combine_daily_files">Combine Daily Files:</label>
                            <input onChange={this.handleOnInputChange} className='confmodal-addrow-checkbox' id="combine_daily_files" name="combine_daily_files" type="checkbox" checked={this.state.combine_daily_files}/><br/>
                          </div>
                         <div style={{marginTop: 10}}>
                            <label for="use_slurm">Use Slurm:</label>
                            <input onChange={this.handleOnInputChange} className='confmodal-addrow-checkbox' id="use_slurm" name="use_slurm" type="checkbox" checked={this.state.use_slurm}/><br/>
                          </div>
                        </form>
                        
                        <div style={{position: 'absolute', bottom: 10}}>
                            <p style={{color: this.state.message_color}}>{this.state.message}</p>
                            <button className="modal-addrow-button" onClick={this.handleSubmit} disabled={this.state.is_loading}>
                                {this.state.add_button_text}
                            </button>
                            <button className="modal-addrow-button cancel-button" onClick={this.props.toggleVisibility} disabled={this.state.is_loading}>
                                Close Window
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}