import React, { Component } from 'react';
import ReactTable from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { default as ReactSelect } from "react-select";

import AddConfDatastreamsModal from "./AddConfDatastreamsModal.js";

import 'react-table/react-table.css'

import './ProcessConfigTable.css'

export default class ProcessConfigTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            sortBy: { value: 'ds_code', label: 'Datastream Code' },
            addDatastreamsModalIsVisible: false
        }

    }

    toggleAddDatastreamsModal = () => {
      this.setState({
        addDatastreamsModalIsVisible: !this.state.addDatastreamsModalIsVisible
      })
    }


    renderProcessingEnabledCell = (cellInfo) => {
        console.log(cellInfo)
        return (
          <div>
           {cellInfo.original.processing_enabled ?
            
                <div style={{textAlign:'center'}}>
                    <div onClick={() => {this.handleToggleProcessingEnabled(cellInfo.original)}} className="glow-indicator enabled"></div>
                </div>
                    
            :
                <div style={{textAlign:'center'}}>
                    <div onClick={() => {this.handleToggleProcessingEnabled(cellInfo.original)}} className="glow-indicator disabled"></div>
                </div>
            }
          </div>
        );
    }

    renderProcessingScheduleCell = (cellInfo) => {
        return (

            <div>
                <FontAwesomeIcon className='sidebar-button-icon' icon={faCalendar} />
            </div>

        );
    }

    renderBoolCell = (cellInfo) => {
        return (
          <div>
            <select
              onChange={(event)=>{this.props.modifyDatastreamConf(cellInfo.column.id, cellInfo.original, event.target.value, (message) => {
                console.log(message)
              })}}
              className='assignment-table-select'
              value={cellInfo.value}
            >
              <option value={true}>{'True'}</option>
              <option value={false}>{'False'}</option>
            </select>

          </div>
        );
    }

    renderNumericCell = (cellInfo) => {
        return (
          <div>
            <input type="number" value={cellInfo.value} onChange={(event)=>{this.props.modifyDatastreamConf(cellInfo.column.id, cellInfo.original, event.target.value, (message) => {
                console.log(message)
              })}}/>
          </div>
        );
      
    }

    renderDsCount = (cellInfo) => {
        return (
          <div>
           {cellInfo.value.length}
          </div>
        );
    }

    renderDsCodeCell = (cellInfo) => {
        return (
          <div>
           <b>{cellInfo.value}</b>
          </div>
        );
    }

    renderDatastreamCell = (cellInfo) => {
        let cellVal = ""
        let site = cellInfo.value.substring(0,4)
        if (site.includes("amf")) {
            let newSite = 'ERR'
            console.log(site, this.props.amfCodes)
            if (site in this.props.amfCodes) {
                newSite = this.props.amfCodes[site]
            }
            
            cellVal =  newSite + cellInfo.value.substring(4)
        } 
        else {
            cellVal = cellInfo.value
        }

        return (
          <div>
           {cellVal}
          </div>
        );
    }

    handleToggleProcessingEnabled = (rowdata) => {
        console.log(rowdata)
        this.props.toggleProcessingEnabled(rowdata, (message) => {
            if (message !== "Success") {
                alert("ERROR: \n" + message);
            }
        })
    }

    handleDeleteRow = (rowdata) => {
        var result = window.confirm("Are you sure you want to delete " + rowdata['datastream'] + " from the DQ Scheduler configuration database?");
        console.log(rowdata)
        if (result) {
            this.props.deleteDatastreamConf(rowdata, (message) => {
                if (message !== "Success") {
                    alert("ERROR DELETING ENTRY: \n" + message);
                }
            })
        }
        
      }

    handleGroupByChange = (option) => {
        this.setState({
            sortBy: option
        })
    }


    render() {
        var tableData = []
        var columns = []

        if(this.state.sortBy.value === "ds_code"){
            for (let i=0; i<this.props.schedulerConf.length; i++) {
                let currConf = this.props.schedulerConf[i]
                let rowIndex = tableData.findIndex(row => row.ds_code === currConf.ds_code);

                if(rowIndex !== -1){
                    tableData[rowIndex]['datastream_confs'].push(currConf)
                } else{
                    tableData.push({
                        ds_code: currConf.ds_code,
                        datastream_confs: [currConf]
                    })
                }
            }

        }
        else {
            for (let i=0; i<this.props.schedulerConf.length; i++) {
                let currConf = this.props.schedulerConf[i]
                let rowIndex = tableData.findIndex(row => row.site === currConf.site);

                if(rowIndex !== -1){
                    tableData[rowIndex]['datastream_confs'].push(currConf)
                } else{
                    tableData.push({
                        site: currConf.site,
                        datastream_confs: [currConf]
                    })
                }
            }


        }

        columns = [{
            Header: this.state.sortBy.value === "ds_code" ? 'Datastream Code' : "Site",
            accessor: this.state.sortBy.value === "ds_code" ? 'ds_code' : "site",
            Cell: this.renderDsCodeCell
        },{
            Header: 'Total Datastreams',
            accessor: 'datastream_confs',
            Cell: this.renderDsCount, 
            width: 200,
            Filter: ({ filter, onChange }) => null
        }]

        const filterOptions = [
          { value: 'ds_code', label: 'Datastream Code' },
          { value: 'site', label: 'Site' },
        ]

        


        return (
            this.props.visible ?
                <div>
                    { this.state.addDatastreamsModalIsVisible 
                        ?
                        <AddConfDatastreamsModal
                          addDatastreamConf={this.props.addDatastreamConf}
                          toggleVisibility={this.toggleAddDatastreamsModal}
                        ></AddConfDatastreamsModal>
                        :
                        null
                      }
                    <div className='config-table-container'>
                        <div style={{height: 40, textAlign: 'left'}}>
                            <p style={{display: 'inline-block', margin:0, paddingRight:20}}>Group By:</p>
                            <div style={{width:300, display:'inline-block'}}>
                                <ReactSelect
                                    value={this.state.sortBy}
                                    options={filterOptions}
                                    onChange={this.handleGroupByChange}
                                    placeholder="Group by..."
                                    isClearable={false}
                                  />
                              </div>
                        </div>

                        <div style={{height: "calc(100% - 40px)"}}>
                            <ReactTable
                                data={tableData}
                                filterable
                                collapseOnDataChange={false}
                                defaultPageSize={50}
                                defaultSorted={this.state.sortBy.value === "ds_code" ? [{id: "ds_code",desc: false}] : [{id: "site",desc: false}]}
                                    
                                columns={columns}
                                style={{
                                    height: "100%",
                                    textAlign: "left"
                                }}
                                SubComponent={row => {
                                    return (
                                      <div>
                                      {row.original['datastream_confs'].length !==0 ?
                                        <div>
                                          <ReactTable
                                            data={row.original['datastream_confs']}
                                            filterable
                                            collapseOnDataChange={false}
                                            defaultSorted={[{id: "datastream_confs",desc: false}]}
                                            columns={[{
                                                    Header: '',
                                                    accessor: 'processing_enabled',
                                                    width: 75,
                                                    Cell: this.renderProcessingEnabledCell,
                                                    filterMethod: (filter, row) => {
                                                      if (filter.value === "all") {
                                                        return true;
                                                      }
                                                      else if (filter.value === 'On'){
                                                        return row[filter.id]
                                                      }
                                                      else if (filter.value === 'Off'){
                                                        return !row[filter.id]
                                                      }
                                                      return String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
                                                    },
                                                    Filter: ({ filter, onChange }) =>
                                                      <select
                                                        onChange={event => onChange(event.target.value)}
                                                        style={{ width: "100%" }}
                                                        value={filter ? filter.value : "all"}
                                                      >
                                                        <option value="all">All</option>
                                                        <option value="On">On</option>
                                                        <option value="Off">Off</option>
                                                        
                                                      </select>
                                                }, {
                                                    Header: 'Datastream',
                                                    accessor: 'datastream',
                                                    Cell: this.renderDatastreamCell
                                                }, {
                                                    Header: 'Raw Data',
                                                    accessor: 'is_raw_data',
                                                    width: 100,
                                                    Cell: this.renderBoolCell,
                                                    Filter: ({ filter, onChange }) => null
                                                }, {
                                                    Header: 'Combine Files',
                                                    accessor: 'combine_daily_files',
                                                    width: 125,
                                                    Cell: this.renderBoolCell,
                                                    Filter: ({ filter, onChange }) => null
                                                }, {
                                                    Header: 'Use Slurm',
                                                    accessor: 'use_slurm',
                                                    width: 125,
                                                    Cell: this.renderBoolCell,
                                                    Filter: ({ filter, onChange }) => null
                                                }, {
                                                    Header: 'Data Not Available Threshold (hrs)',
                                                    accessor: 'missing_data_threshold',
                                                    width: 275,
                                                    Cell: this.renderNumericCell,
                                                    Filter: ({ filter, onChange }) => null
                                                    }, 
                                                //{
                                                //     Header: '',
                                                //     accessor: 'processing_enabled',
                                                //     width: 35,
                                                //     Cell: this.renderProcessingScheduleCell
                                                // }, 
                                                {
                                                    Header: '',
                                                    width: 30,
                                                    Filter: ({ filter, onChange }) =>
                                                      <div></div>,
                                                    Cell: row => (
                                                       <div>
                                                          <FontAwesomeIcon className='config-table-delete' icon={faTimes} onClick={() => {this.handleDeleteRow(row.original)}} />
                                                       </div>
                                                   )

                                            }]}
                                            defaultPageSize={row.original['datastream_confs'].length}
                                            showPagination={false}
                                            style={{border: '1px solid rgba(0,0,0,0.3)'}}
                                          />

                                        </div>
                                        :
                                        null
                                        }
                                      </div>
                                    )
                                }}
                            />
                        </div>
                        <div onClick={this.toggleAddDatastreamsModal} className='add-row-button'>
                          <p>Add Datastream(s)</p>
                          <FontAwesomeIcon icon={faPlus}  />
                        </div>
                    </div>
                </div>
            :
                null
        )
    } 
}