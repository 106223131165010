import React, { Component } from 'react';
import ReactTable from 'react-table';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faCalendar } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import AddRowModal from "./AddRowModal.js";
import AddDatastreamsModal from "./AddDatastreamsModal.js";
import ScheduleAssignmentModal from "./ScheduleAssignmentModal.js";

import 'react-table/react-table.css'
import './AssignmentTable.css'

library.add(faPlus, faTimes)

export default class AssignmentTable extends Component {

  constructor(props){
    super(props);

    this.state ={
      addRowModalIsVisible: false,
      addDatastreamsModalIsVisible: false,
      scheduleAssignmentModalIsVisible: false,
      viewAllScheduledAssignmentsModalIsVisible: false,
      scheduleAssignmentModalCurrentAnalyst: null,
      scheduleAssignmentModalCurrentInst: null,
      scheduleAssignmentModalCurrentSite: null,
      scheduleAssignmentModalCurrentScheduledAssignments: null,
      currentRow: {}
    }
  }

  toggleAddRowModal = () => {
    this.setState({
      addRowModalIsVisible: !this.state.addRowModalIsVisible
    })
  }

  toggleViewAllScheduledAssignmentsModal = () => {
    this.setState({
      viewAllScheduledAssignmentsModalIsVisible: !this.state.viewAllScheduledAssignmentsModalIsVisible
    })
  }

  toggleAddDatastreamsModal = (row) => {
    this.setState({
      currentRow: row
    }, ()=> {
      this.setState({
        addDatastreamsModalIsVisible: !this.state.addDatastreamsModalIsVisible
      })
    })
  }

  toggleScheduleAssignmentModal = (currentAnalyst, currentSite, currentInst, currentScheduledAssignments) => {
    this.setState({
      scheduleAssignmentModalCurrentAnalyst: currentAnalyst,
      scheduleAssignmentModalCurrentInst: currentInst,
      scheduleAssignmentModalCurrentSite: currentSite,
      scheduleAssignmentModalCurrentScheduledAssignments: currentScheduledAssignments
    }, () => {
      this.setState({
        scheduleAssignmentModalIsVisible: !this.state.scheduleAssignmentModalIsVisible
      })
    })
  }

  renderAnalystDropdown = (cellInfo) => {
    let hasScheduledAssignment = cellInfo.original.site_code+cellInfo.original.instrument_assignment in this.props.scheduledAssignments
    let scheduledAssignments = null
    if(hasScheduledAssignment){
      scheduledAssignments = this.props.scheduledAssignments[cellInfo.original.site_code+cellInfo.original.instrument_assignment]
    }
    return (
      <div>
        <select
          onChange={(event)=>{this.props.updateAssignment(cellInfo.original.id, cellInfo.original.site_code, cellInfo.original.instrument_assignment, event.target.value)}}
          className='assignment-table-select'
          // defaultValue={cellInfo.original.person_id}
          value={cellInfo.value}
        >
        <option value={''}>{''}</option>
        {
          this.props.analysts.map( (analyst, index)=> {
            return <option key={index} value={analyst.name}>{analyst.name}</option>
          })
        }
        </select>
        <FontAwesomeIcon style={hasScheduledAssignment ? {color:'green'} : {}} className='assignment-table-select-cal-icon' icon={faCalendar} onClick={() => {this.toggleScheduleAssignmentModal(cellInfo.value, cellInfo.original.site_code, cellInfo.original.instrument_assignment, scheduledAssignments)}} />
      </div>
    );
  }

  renderDeploymentStatusDropdown = (cellInfo) => {
    return (
      <div>
       {this.props.editable ?
        <select
          onChange={(event)=>{this.props.updateAssignmentMetadata("deployment_status", cellInfo.original.id, event.target.value)}}
          className='assignment-table-select'
          value={cellInfo.value}
        >
          <option value={'Deployed'}>{'Deployed'}</option>
          <option value={'Not Deployed'}>{'Not Deployed'}</option>
        </select>
        :
          cellInfo.value
        }
      </div>
    );
  }

  renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable={this.props.editable}
        suppressContentEditableWarning
        onBlur={e => {

          
          let new_site = cellInfo.column.id === 'site_code' ? e.target.innerHTML : cellInfo.original.site_code
          let new_instrument = cellInfo.column.id === 'instrument_assignment' ? e.target.innerHTML : cellInfo.original.instrument_assignment
          if(new_site !== cellInfo.original.site_code || new_instrument !== cellInfo.original.instrument_assignment){
            var result = window.confirm("Are you sure you want to edit this row?");
            if (result) {
              this.props.updateAssignment(cellInfo.original.id, new_site, new_instrument, cellInfo.original.person_id)
            }
            else{
              e.persist()
              e.target.innerHTML = cellInfo.column.id === 'site_code' ? cellInfo.original.site_code : cellInfo.original.instrument_assignment
            }
          }

         
        }}
        dangerouslySetInnerHTML={{
          __html: this.props.data[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  renderDatastreamCount = (cellInfo) => {
    console.log(cellInfo)
    return(
      <div>

      </div>
    )
  }

  handleAddScheduledAssignment = (person_id, site, inst, date) => {
    this.props.addScheduledAssignment(person_id, site, inst, date, (assignments) => {
      this.setState({scheduleAssignmentModalCurrentScheduledAssignments: assignments})
    })
  }

  handleDeleteScheduledAssignment = (person_id, site, inst, date) => {
    this.props.deleteScheduledAssignment(person_id, site, inst, date, (assignments) => {
      this.setState({scheduleAssignmentModalCurrentScheduledAssignments: assignments})
    })
  }

  handleDeleteRow = (rowdata) => {

    var result = window.confirm("Are you sure you want to delete this row?");
    console.log(rowdata)
    if (result) {
        this.props.deleteAssignment(rowdata.id)
    }
    
  }

  render() {

    const hasScheduledAssignment = (this.props.scheduledAssignments !== undefined && this.props.scheduledAssignments !== null && Object.keys(this.props.scheduledAssignments).length > 0)

    const columns = [
      {
        Header: '#',
        accessor: 'assignment_metadata.datastreams.length',
        width: 30,
        Filter: ({ filter, onChange }) =>
          <div></div>
      },
      {
        Header: 'Site',
        accessor: 'site_code',
        // width: 200,
        Cell: this.renderEditable
      },
      {
        Header: 'Instrument',
        accessor: 'instrument_assignment',
        // width: 200
        Cell: this.renderEditable
      },
      {
        Header: 'Analyst',
        accessor: 'person_id',
        Cell: this.renderAnalystDropdown,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          else if (filter.value === 'unassigned'){
            return row[filter.id] === ''
          }
          return String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
        },
        Filter: ({ filter, onChange }) =>
        <div>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "calc(100% - 30px)", marginRight: 5 }}
            value={filter ? filter.value : "all"}
          >
          <option value="all">All</option>
          <option value="unassigned">Unassigned</option>
          {
            this.props.analysts.map( (analyst, index)=> {
              return <option key={index} value={analyst.name}>{analyst.name}</option>
            })
          }
            
          </select>
          <Tooltip
            interactive
            html = {"Click to see all scheduled assignment changes"}
            position="bottom"
            trigger="mouseenter"
            arrow="true"
            duration='50'
            unmountHTMLWhenHide={true}>
              <FontAwesomeIcon style={hasScheduledAssignment ? {color:'green'} : {}} className='assignment-table-select-cal-icon' icon={faCalendar} onClick={this.toggleViewAllScheduledAssignmentsModal} />
          </Tooltip>
           
        </div>
      },
      {
        Header: "Deployment Status",
        id: "deployment_status",
        accessor: 'assignment_metadata.deployment_status',
        Cell: this.renderDeploymentStatusDropdown,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          else if (filter.value === 'Deployed'){
            return row[filter.id] === 'Deployed'
          }
          return String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase());
        },
        Filter: ({ filter, onChange }) =>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "Deployed"}
          >
            <option value="all">All</option>
            <option value="Deployed">Deployed</option>
            <option value="Not Deployed">Not Deployed</option>
            
          </select>

      }
    ]

    if(this.props.editable){
      columns.push({
        Header: '',
        width: 25,
        Filter: ({ filter, onChange }) =>
          <div></div>,
        Cell: row => (
           <div>
              <FontAwesomeIcon className='assignment-table-delete' icon={faTimes} onClick={() => {this.handleDeleteRow(row.original)}} />
           </div>
       )
      })
    }

    return(
      this.props.visible ?
        <div>
          { this.state.addRowModalIsVisible 
            ?
            <AddRowModal
              analysts={this.props.analysts}
              toggleVisibility={this.toggleAddRowModal}
              addAssignment={this.props.addAssignment}
            ></AddRowModal>
            :
            null
          }
          { this.state.addDatastreamsModalIsVisible 
            ?
            <AddDatastreamsModal
              row={this.state.currentRow}
              toggleVisibility={this.toggleAddDatastreamsModal}
              updateAssignmentMetadata={this.props.updateAssignmentMetadata}
            ></AddDatastreamsModal>
            :
            null
          }


            
            <ScheduleAssignmentModal
              editable={this.props.editable}
              isOpen={this.state.scheduleAssignmentModalIsVisible}
              analysts={this.props.analysts}
              currentAnalyst={this.state.scheduleAssignmentModalCurrentAnalyst}
              currentSite={this.state.scheduleAssignmentModalCurrentSite}
              currentScheduledAssignments={this.state.scheduleAssignmentModalCurrentScheduledAssignments}
              currentInst={this.state.scheduleAssignmentModalCurrentInst}
              toggleVisibility={this.toggleScheduleAssignmentModal}
              handleAddScheduledAssignment={this.handleAddScheduledAssignment}
              handleDeleteScheduledAssignment={this.handleDeleteScheduledAssignment}
            ></ScheduleAssignmentModal>

          <Modal open={this.state.viewAllScheduledAssignmentsModalIsVisible} onClose={this.toggleViewAllScheduledAssignmentsModal}>
              <p className="modal-title"><b>Scheduled Assignment Changes</b></p>
              <div>
                  <table className="scheduled-assignment-change-table" >
                      <tbody>
                        <tr>
                          <th>Site</th>
                          <th>Instrument</th>
                          <th>Analyst</th>
                          <th>Date</th>
                        </tr>
                          {
                              Object.keys(this.props.scheduledAssignments).sort().map((key, index) => {

                                const site = key.substring(0, 3)
                                const inst = key.substring(3)
                                return(
                                 <>
                                   {this.props.scheduledAssignments[key].sort((a, b) => (moment(a['date']).format('YYYYMMDD') > moment(b['date']).format('YYYYMMDD')) ? 1 : -1).map((data, index) => {

                                      let analyst_name = data['person_id']
                                      for (let i=0; i<this.props.analysts.length; i++){
                                          let curr_analyst = this.props.analysts[i]
                                          if (data['person_id'].toString() === curr_analyst.armid){
                                              analyst_name = curr_analyst.name
                                          }
                                      }
                                     return(
                                       <tr>
                                         <td>
                                           {site}
                                         </td>
                                         <td>
                                           {inst}
                                         </td>
                                          <td>
                                              {analyst_name}
                                          </td>
                                          <td>
                                            {moment(data['date']).format('YYYY-MM-DD')}
                                          </td>
                                          <td style={{textAlign:'center'}}>
                                              <FontAwesomeIcon className='assignment-table-delete' icon={faTimes} onClick={this.props.editable ? () => {this.handleDeleteScheduledAssignment(data['person_id'], site, inst, data['date'])} : null} />
                                          </td>
                                        </tr>
                                    )
                                   })}
                                 </>
                                ) 
                              })
                          }
                      </tbody>
                  </table>
              </div>
          </Modal>

          
          <div  className='assignment-table-container'>
            <ReactTable
              data={this.props.data}
              columns={columns}
              style={{
                height: "100%",
                textAlign: "left"
              }}
              filterable
              defaultFilterMethod = {(filter, row) =>
                String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())}
              defaultFiltered={[
              {
                id: 'deployment_status',
                value: 'Deployed'
              }]}
              defaultPageSize={50}
              defaultSorted={[
                {
                  id: "site_code",
                  desc: false
                }
              ]}
              SubComponent={row => {
                let rowdata = []
                if(row.original.assignment_metadata != null){
                  if(typeof row.original.assignment_metadata.datastreams !== 'undefined'){
                    rowdata = row.original.assignment_metadata.datastreams.map((datastream) => 
                    {
                      return {'datastream': datastream}
                    })
                  }
                }
                return (
                  <div>
                  {rowdata.length!==0 ?
                    <div>
                      <ReactTable
                        data={rowdata}
                        columns={[
                          {
                            Header: 'DQ-Explorer Datastreams',
                            accessor: 'datastream'
                          }
                        ]}
                        defaultPageSize={rowdata.length}
                        showPagination={false}
                        style={{border: '1px solid rgba(0,0,0,0.3)'}}
                      />
                    </div>
                    :
                    null
                  }
                  {this.props.editable ?
                    <div className='datastream-addrow-button' onClick={() => {this.toggleAddDatastreamsModal(row)}}>
                      <p>Add/Remove Datastreams</p>
                    </div>
                  : 
                    null
                  }
                  </div>
                )
              }}
            />
          </div>
          {this.props.editable ?
            <div onClick={this.toggleAddRowModal} className='add-row-button'>
              <p>Add Row</p>
              <FontAwesomeIcon icon={faPlus}  />
            </div>
          :
            null
          }

        </div>
      :
        null
    )
  }
}