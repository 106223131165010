import React, { Component } from 'react';

import NewWindow from 'react-new-window';
import Collapsible from 'react-collapsible';
import { default as ReactSelect } from "react-select";
import axios from 'axios';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import DatePicker from 'react-datepicker';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import 'react-datepicker/dist/react-datepicker.css';

import './ProcessStatusChart.css';

const moment = extendMoment(Moment);

// const CancelToken = axios.CancelToken;
// let cancel;


// //Extend Select module to allow user to select "all"
// const Select = props => { 
//   if (props.allowSelectAll) {
//     if ((props.value.length === props.options.length) && props.value.length>1) {
//       return (
//         <ReactSelect
//           {...props}
//           value={[props.allOption]}
//           onChange={(selected, { action }) => { props.onChange(selected.slice(1), action)}}
//         />
//       );
//     }

//     return (
//       <ReactSelect
//         {...props}
//         options={[props.allOption, ...props.options]}
//         onChange={(selected, { action }) => {
//           if (
//             selected.length > 0 &&
//             selected[selected.length - 1].value === props.allOption.value
//           ) {
//             return props.onChange(props.options, action);
//           }
//           return props.onChange(selected, action);
//         }}
//       />
//     );
//   }

//   return <ReactSelect {...props} />;
// };
// Select.propTypes = {
//   options: PropTypes.array,
//   value: PropTypes.any,
//   onChange: PropTypes.func,
//   allowSelectAll: PropTypes.bool,
//   allOption: PropTypes.shape({
//     label: PropTypes.string,
//     value: PropTypes.string
//   })
// };
// Select.defaultProps = {
//   allOption: {
//     label: "All",
//     value: "*"
//   }
// };


class ProcessStatusDisplay extends Component {

  render() {
    return (
      this.props.isOpen 
      ? 
      <NewWindow onUnload={this.props.onClose} copyStyles={true} title='Process Details' name='process_details'>

        <div className="ProcessStatusDisplay">

          {this.props.data.length === 0 
            ?
            <div className="process-details-loading-container">
              <h3>Loading...</h3>
            </div>
            
            :
            this.props.data.map((process, index)=> {

              let hasMetrics = process.metrics.length > 0
              let hasPlots   = process.plots.length > 0
              let hasErrors  = process.errors.length > 0

              let triggerColor = "#00bd4b"

              if (!hasMetrics || !hasPlots || hasErrors) {
                  // Missing output + no errors
                  if ((!hasMetrics || !hasPlots) && !hasErrors) {
                    triggerColor = '#1a66e4'
                  } 
                  // Missing output + errors
                  else if ((!hasMetrics || !hasPlots) && hasErrors) {
                    triggerColor = 'red'
                  }
                  // Has output + errors
                  else if ((hasMetrics && hasPlots) && hasErrors) {
                    triggerColor = 'yellow'
                  }
              }

              return(
                <Collapsible key={process.process_id} triggerStyle={{background:triggerColor, color:"#000000"}} trigger={process.process_id + " (" + process.process_start_time + ")"}>
                  <ul>
                    <li><b>Filename:</b> {process.filename}</li>
                    <li><b>Data Date:</b> {process.data_date}</li>
                    <li><b>Run Time:</b> {process.elapsed_seconds + "s"}</li>
                    <li><b>Metric(s):</b> {"\n" + process.metrics.join("\n")}</li>
                    <li><b>Plot(s):</b> {"\n" + process.plots.join("\n")}</li>
                    <li><b>Errors:</b> {"\n" + process.errors}</li>
                  </ul>
                </Collapsible>
              )
            })
          }
        </div>
      </NewWindow>
      :
      null
    );
  }
}

class ProcessStatusChartTooltip extends Component {

  render() {

    return(
      <div className="process-tooltip">

        {this.props.numProcesses > 0 ?

          <div>
            <div className="process-tooltip-section">
              <h2>Daily Process Count: {this.props.numProcesses}</h2>
            </div>


            <div className="process-tooltip-section">
            { this.props.numErrors > 0 ?
              <h3 style={{color:'red', textDecoration:"none", fontSize:18}}>Error(s) Detected</h3>
              :
              null
            }
            </div>

            <div className="process-tooltip-section">
              <h3>Generated Metrics:</h3>
              <ul>
              {
                this.props.metrics.map((metrics, index)=> {
                  let splitMetricsName = metrics.split("/")
                  return(
                    <li key={index}>{splitMetricsName[splitMetricsName.length-1]}</li>
                  )
                })
              }
              </ul>
            </div>

            <div className="process-tooltip-section">
              <h3>Generated Plots:</h3>
              <ul>
              {
                this.props.plots.map((plot, index)=> {
                  let splitPlotName = plot.split("/")
                  return(
                    <li key={index}>{splitPlotName[splitPlotName.length-1]}</li>
                  )
                })
              }
              </ul>
            </div>
            <div className="process-tooltip-section">
              <p style={{cursor:"pointer"}} onClick={
                () => {this.props.openProcessDisplay(this.props.datastream, this.props.date)}
              }>See More</p>
            </div>
          </div>

          :

          <div>
            <div className="process-tooltip-section">
              <h2>No Data Processed</h2>
            </div>
            <div className="process-tooltip-section">
              <h3>Data Availability:</h3>

              {this.props.hasDataFile ? 
                <p style={{color:"#00bd4b", fontSize:18}}>Data file(s) exist</p>
                :
                <p style={{color:"#ff0000",  fontSize:18}}>No data file(s) exist</p>
              }
              
            </div>
          </div>
        }

      </div>
    )
  }
}

class LegendTooltip extends Component {

  render() {
    return(
      <div className="legend-tooltip">
        <div className="color-box" style={{backgroundColor: "gray"}}></div>
        <div className="color-description">No data file(s)</div>
        <br/>

        <div className="color-box" style={{backgroundColor: "#00bd4b"}}></div>
        <div className="color-description">No issues detected</div>
        <br/>


        <div className="color-box" style={{backgroundColor: "#1a66e4"}}></div>
        <div className="color-description">Missing output, no errors occurred</div>
        <br/>


        <div className="color-box" style={{backgroundColor: "yellow"}}></div>
        <div className="color-description">Processing error(s) occurred, has output</div>
        <br/>



        <div className="color-box" style={{backgroundColor: "red"}}></div>
        <div className="color-description">Processing error(s) occurred, no output</div>
        <br/>

      </div>
    )
  }
}


export default class ProcessStatusChart extends Component {

  constructor(props){
    super(props)

    this.state = {
      processDisplayIsOpen: false,
      processDisplayData: [],
      displayFilterText: "",
      displayFilterValue: null,
      startDate: "",
      endDate: "",
      tableStartDate: "",
      tableEndDate: ""
    }
  }

  getProcessingDetails = (datastream, date) => {
    let query_data = {
      'date': date,
      'datastream': datastream
    }

    axios.post(this.props.apiBaseURL + 'get_processing_details', query_data, {
      // cancelToken: new CancelToken(function executor(c) {
      //   // An executor function receives a cancel function as a parameter
      //   // cancel = c;
      // })
    })
    .then(res => {
      this.setState({
        processDisplayData: res.data.processes
      })
    })
  }


  onFilterTextChange = (e) => {
    this.setState({displayFilterText: e.target.value});
  }
  handleChangeStart = (date) => {
    this.setState({startDate: date})
  }
  handleChangeEnd = (date) => {
    this.setState({endDate: date})
  }
  getStatus = () => {
    this.props.toggleLoadIndicator(true)

    let sdate = this.state.startDate === "" ? moment(this.props.dqaStart, 'YYYY-MM-DD') : moment(this.state.startDate)
    let edate = this.state.endDate === "" ? moment(this.props.dqaEnd, 'YYYY-MM-DD') : moment(this.state.endDate)
    
    this.props.getProcessStatus(sdate.format('YYYYMMDD'), edate.format('YYYYMMDD'), () => {
      this.setState({
        tableStartDate: this.state.startDate,
        tableEndDate: this.state.endDate
      })
      this.props.toggleLoadIndicator(false)
    })
  }

  openProcessDisplay = (datastream, date) => {
    this.setState({
      processDisplayIsOpen: true,
    }, () => {
      this.getProcessingDetails(datastream, date)
    })
  }

  onProcessDisplayClose = () => {
     this.setState({
      processDisplayIsOpen: false,
      processDisplayData: []
    })
  }

  handleFilterSelectChange = (option) => {
    this.setState({
        displayFilterValue: option
    })
  }

  render() {

    let rangeSdate = this.state.tableStartDate === "" ? moment(this.props.dqaStart, 'YYYY-MM-DD') : moment(this.state.tableStartDate)
    let rangeEdate = this.state.tableEndDate === "" ? moment(this.props.dqaEnd, 'YYYY-MM-DD') : moment(this.state.tableEndDate)

    let range = moment.range(rangeSdate, rangeEdate);
    let dates = Array.from(range.by('days'));
    dates = dates.map(m => m.format('YYYYMMDD'))

    let datastreams = Object.keys(this.props.data)

    let datePickerSdate = this.state.startDate === "" ? moment(this.props.dqaStart, 'YYYY-MM-DD').toDate() : this.state.startDate
    let datePickerEdate = this.state.endDate === "" ? moment(this.props.dqaEnd, 'YYYY-MM-DD').toDate() : this.state.endDate

    const filterOptions = [
      { value: 'good', label: 'Good' },
      { value: 'no_data', label: 'No Data' },
      { value: 'missing_output', label: 'Missing Output' },
      { value: 'error', label: 'Error' }
    ]

    let legendTooltip = <LegendTooltip/>
    

    return(
    <div>
      <ProcessStatusDisplay onClose={this.onProcessDisplayClose} data={this.state.processDisplayData} isOpen={this.state.processDisplayIsOpen}/>
      { this.props.visible ?
        <div className='dqprocesschart-container'>

        <div className="top-div">
        <div style={{float:"left"}}>
          <input placeholder="Filter by text..." value={this.state.displayFilterText} className="filter-box" onChange={this.onFilterTextChange}></input>
        </div>

        
        <div style={{float:"left", marginLeft:20, width:220, display:"inline-block"}}>
          <div style={{width:90, float:"left"}}>
            <DatePicker
              dateFormat= "yyyy-MM-dd"
              selected={datePickerSdate}
              startDate={datePickerSdate}
              endDate={datePickerEdate}
              onChange={this.handleChangeStart}
              className="filter-box"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div style={{width:90, display:"inline-block"}}>
            <DatePicker
              dateFormat= "yyyy-MM-dd"
              selected={datePickerEdate}

              startDate={datePickerSdate}
              endDate={datePickerEdate}
              onChange={this.handleChangeEnd}
              className="filter-box"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
        <div>
          <button className="submit-button" onClick={this.getStatus}>Get Status</button>
        </div>
        <div>
          <ReactSelect
            className="filter-select-container"
            value={this.state.displayFilterValue}
            options={filterOptions}
            onChange={this.handleFilterSelectChange}
            placeholder="Filter by tag..."
            isClearable={true}
          />
        </div>



        <div style={{float:"right", marginTop:10, height:40}}>
        <Tooltip
          interactive
          html = {legendTooltip}
          position="bottom"
          trigger="mouseenter"
          arrow="true"
          duration='50'
          className="tippy-class"
        >
          <div style={{width:100, height:40}}> 
            <button className="legend-button">Legend</button>
          </div>
        </Tooltip>
        </div>

      </div>
        <table>
          <tbody>
            {
              datastreams.map( (ds, index)=> {
                
                let dateRangeMissingOutput = false
                let dateRangeHasErrors = false
                let dateRangeMissingData = false

                for (let i=0; i<dates.length; i++){
                  let date = dates[i]

                  let plotNames = []
                  let metricsNames = []
                  let numErrors = 0
                  let hasDataFile = false

                  if (date in this.props.data[ds]) {
                      plotNames = this.props.data[ds][date]['plots']
                      metricsNames = this.props.data[ds][date]['metrics']
                      numErrors = this.props.data[ds][date]['num_errors']
                      hasDataFile = this.props.data[ds][date]['has_data_file']
                  }

                  let hasMetrics = metricsNames.length > 0
                  let hasPlots   = plotNames.length > 0
                  let hasErrors  = numErrors > 0

                  if (!hasMetrics || !hasPlots) {
                    dateRangeMissingOutput = true
                  }
                  if (hasErrors) {
                    dateRangeHasErrors = true
                  }
                  if (!hasDataFile) {
                    dateRangeMissingData = true
                  }
                }

                
                let isFiltered = false
                let filterVal = ""
                if (this.state.displayFilterValue) {
                  filterVal = this.state.displayFilterValue.value
                }
                


                if (filterVal === "good"){
                  if(dateRangeMissingOutput || dateRangeHasErrors || dateRangeMissingData){
                    isFiltered = true
                  }
                } else if (filterVal === "missing_output"){
                  if(!dateRangeMissingOutput || dateRangeMissingData){
                    isFiltered = true
                  }
                } else if(filterVal === "no_data") {
                  if(!dateRangeMissingData){
                    isFiltered = true
                  }
                } else if(filterVal === "error") {
                  if(!dateRangeHasErrors){
                    isFiltered = true
                  }
                }


                return(
                  ((this.state.displayFilterText === "" || ds.toLowerCase().includes(this.state.displayFilterText.toLowerCase())) && !isFiltered) ?
                  <tr style={{textAlign:"left"}} key={ds}> 
                    <th>{ds}</th>
                    {

                      dates.map( (date, index)=> {
                        let plotNames = []
                        let metricsNames = []
                        let numProcesses = 0
                        let numErrors = 0
                        let hasDataFile = false

                        let cellColor = '#00bd4b'

                        if (date in this.props.data[ds]) {
                            plotNames = this.props.data[ds][date]['plots']
                            metricsNames = this.props.data[ds][date]['metrics']
                            numProcesses = this.props.data[ds][date]['num_processes']
                            numErrors = this.props.data[ds][date]['num_errors']
                            hasDataFile = this.props.data[ds][date]['has_data_file']
                        }


                        let hasMetrics = metricsNames.length > 0
                        let hasPlots   = plotNames.length > 0
                        let hasErrors  = numErrors > 0

                        if (numProcesses > 0) {
                            // Missing output + no errors
                            if ((!hasMetrics || !hasPlots) && !hasErrors) {
                              cellColor = '#1a66e4'
                            } 
                            // Missing output + errors
                            else if ((!hasMetrics || !hasPlots) && hasErrors) {
                              cellColor = 'red'
                            }
                            // Has output + errors
                            else if ((hasMetrics && hasPlots) && hasErrors) {
                              cellColor = 'yellow'
                            } 
                            else if ((hasMetrics && hasPlots) && !hasErrors) {
                              cellColor = '#00bd4b'
                            } 
                        }
                        else {
                            if (!hasDataFile){
                              cellColor = 'gray'
                            }
                            else{
                                cellColor = 'red'
                            }
                        }

                        let tooltip = <ProcessStatusChartTooltip 
                            openProcessDisplay={this.openProcessDisplay} 
                            datastream={ds}
                            date={date}
                            hasDataFile={hasDataFile}
                            numProcesses={numProcesses}
                            numErrors={numErrors}
                            metrics={metricsNames}
                            plots={plotNames}
                        />

                        return(
                         
                          <th  style={{backgroundColor:cellColor}} key={date}> 
                           <Tooltip
                              interactive
                              html = {tooltip}
                              position="bottom"
                              trigger="mouseenter"
                              arrow="true"
                              duration='50'
                              className="tippy-class"
                              unmountHTMLWhenHide={true}>
                              <div className="tooltip-cell">
                            
                                {date}
                              </div>
                            </Tooltip>
                          </th>
                          
                          
                        )
                      })
                    }
                  </tr>
                  :
                  null
                )
              })
            } 
          </tbody>
        </table>
        </div>
      :
      null
    }
    </div>
    )
  }
}
