import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import { Modal } from "react-responsive-modal";
import moment from 'moment';
import Select from 'react-select'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes} from '@fortawesome/free-solid-svg-icons'


import "react-responsive-modal/styles.css";
import 'react-datepicker/dist/react-datepicker.css';

import './ScheduleAssignmentModal.css'

library.add(faTimes)

export default class ScheduleAssignmentModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            date: new Date(),
            person_id: null,
            selectedOption: null
        }
    }

    handleAnalystChange = (selectedOption) => {
        this.setState({
            selectedOption: selectedOption
        })
    }

    render(){
        const  open  = this.props.isOpen;

        const options = this.props.analysts.map((analyst, index) => {
            return {value: analyst.armid, label:analyst.name}
        })

        const customStyles = {
            menu: (provided, state) => {
              return { 
                ...provided,
                zIndex: 999999
              }
            },
            menuPortal: (provided, state) => {
              return { 
                ...provided,
                zIndex: 999999
              }
            }
        }

        return(
             <Modal open={open} onClose={() => {this.props.toggleVisibility(null, null, null, null)}}>
                <p className="modal-title"><b>Schedule Assignment Change for {this.props.currentSite} {this.props.currentInst}</b></p>
                
                <div className="datepicker-container">
                    <DatePicker
                        dateFormat= "yyyy-MM-dd"
                        inline 
                        selected={this.state.date}
                        onChange={(date) => this.setState({date:date})}
                    />
                </div>
                <p className="modal-subtitle"><b>Current analyst:</b> {this.props.currentAnalyst}</p>
                <p className="modal-subtitle"><b>New analyst:</b></p>

                <Select 
                    value={this.state.selectedOption}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    className='analyst-select-container'
                    options={options}
                    styles={customStyles}
                    onChange={this.handleAnalystChange}
                 />

               {/* <select onChange={this.handleAnalystChange} className="modal-select">
                {
                  this.props.analysts.map( (analyst, index)=> {
                    return <option value={analyst.armid} key={index}>{analyst.name}</option>
                  })
                }
                </select>*/}

                <button disabled={!this.props.editable || this.state.selectedOption === null} className="modal-submit-button" onClick={() => {this.props.handleAddScheduledAssignment(this.state.selectedOption.value, this.props.currentSite, this.props.currentInst, this.state.date)}}>
                    Add Scheduled Change
                </button>

                { this.props.currentScheduledAssignments !== null && this.props.currentScheduledAssignments !== undefined ? 
                <div>
                    <p className="modal-subtitle"><b>Scheduled Changes:</b></p>
                    <table className="scheduled-assignment-change-table" >
                        <tbody>
                            {
                                this.props.currentScheduledAssignments.map((data, index) => {
                                    return <ScheduledAssignmentItem key={'item'+index.toString()} data={data} analysts={this.props.analysts} site={this.props.currentSite} inst={this.props.currentInst} editable={this.props.editable} handleDeleteScheduledAssignment={this.props.handleDeleteScheduledAssignment}/>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                :
                    null
                }
            </Modal>
        )
    }
}

class ScheduledAssignmentItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render(){
        let analyst_name = this.props.data['person_id']

        for (let i=0; i<this.props.analysts.length; i++){
            let curr_analyst = this.props.analysts[i]
            if (this.props.data['person_id'].toString() === curr_analyst.armid){
                analyst_name = curr_analyst.name
            }
        }


        return(
            <tr>
                <td>
                    {analyst_name}
                </td>
                <td>

                    {moment(this.props.data['date']).format('YYYY-MM-DD')}

                </td>
                <td style={{textAlign:'center'}}>
                    <FontAwesomeIcon className='assignment-table-delete' icon={faTimes} onClick={this.props.editable ? () => {this.props.handleDeleteScheduledAssignment(this.props.data['person_id'], this.props.site, this.props.inst, this.props.data['date'])} : null} />
                </td>
            </tr>
        )
    }
}