import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { CircularProgressbar } from 'react-circular-progressbar';
import DatePicker from 'react-datepicker';
import NewWindow from 'react-new-window'
import Collapsible from 'react-collapsible';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import './HomePage.css'

const moment = extendMoment(Moment);

export default class HomePage extends Component {

  constructor(props){
    super(props)

    this.state = {
      processErrorDetailsWindowIsOpen: false,
      processErrorDetailsData: []
    }
  }

  openProcessingErrorsDetailsWindow = (errors) => {
    this.setState({
      processErrorDetailsWindowIsOpen: true,
      processErrorDetailsData: errors
    })
  }

  onProcessingErrorsDetailsWindowClose = () => {
     this.setState({
      processErrorDetailsWindowIsOpen: false,
      processErrorDetailsData: []
    })
  }

  render(){
    return(
      <div>
        <ProcessingErrorDetailsWindow  
          startDate={this.props.errorDisplayStartDate} 
          endDate={this.props.errorDisplayEndDate} 
          onClose={this.onProcessingErrorsDetailsWindowClose} 
          data={this.props.processingErrors} 
          isOpen={this.state.processErrorDetailsWindowIsOpen}
        />
      {
      this.props.visible ?
        <div style={{padding:20}}>
          <Grid fluid>
            <Row>
             <Col sm={12} md={6} lg={6}>
                <QuickLinks/>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <DQAWeek dqaStart={this.props.dqaStart} dqaEnd={this.props.dqaEnd}/>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <ProcessingErrorDisplay 
                  processingErrors={this.props.processingErrors}
                  startDate={this.props.errorDisplayStartDate} 
                  endDate={this.props.errorDisplayEndDate} 
                  handleChangeStart={this.props.handleChangeErrorDisplayStart}
                  handleChangeEnd={this.props.handleChangeErrorDisplayEnd}
                  getProcessingErrors={this.props.getProcessingErrors}
                  openProcessingErrorsDetailsWindow={this.openProcessingErrorsDetailsWindow}
                  schedulerStatus={this.props.schedulerStatus}
                />
              </Col>
              <Col sm={12} md={12} lg={6}>
                <DQAProgress dqasComplete={this.props.dqasComplete}/>
              </Col>
            </Row>

          </Grid>
        </div>
      :
        null
      }
      </div>
    )
  }
}

class ProcessingErrorDetailsWindow extends Component {

  render() {
    return (
      this.props.isOpen 
      ? 
      <NewWindow onUnload={this.props.onClose} copyStyles={true} title='Error Details' name='error_details'>

        <div>
          <h2 style={{padding:10}}>DQ Processing Errors for {moment(this.props.startDate).format('YYYYMMDD') + " - " + moment(this.props.endDate).format('YYYYMMDD')}</h2>
          {
            Object.keys(this.props.data).map((ds, dsindex)=> {
              return(
                <Collapsible key={ds} trigger={ds + " (" + this.props.data[ds].length + ")"}>
                  {
                    this.props.data[ds].map((error, errindex)=> {
                      return(
                        <div key={ds+errindex}>
                          <h3 className="processing-error-details-header">ERROR {errindex+1}</h3>
                          <p className="processing-error-details-text">{error}</p>
                        </div>
                      )
                    })
                  }
                </Collapsible>
              )
            })
          }
        </div>
      </NewWindow>
      :
      null
    );
  }
}

class QuickLinks extends Component {

    render(){
      return(
        <div className={"item-container"}>
          <p className={"item-title"}>Helpful Links:</p>
          <a href="https://time.ou.edu/" target="_blank" rel="noreferrer"><p>Online Timesheets</p></a>
          <a href="https://dq.arm.gov/" target="_blank" rel="noreferrer"><p>DQ Homepage</p></a>
          <a href="https://dqwiki.arm.gov/dqcms/bin/view/DQdocs/" target="_blank" rel="noreferrer"><p>DQ Wiki</p></a>
        </div>
      )
    }

}

class ProcessingDisplayDatastreamTooltip extends Component {

  render(){
    return (
      <div>
        {
          this.props.datastreams.map((ds, index)=> {
            return (
              <p style={{margin:0, padding:0}}>{ds}</p>
            )
          })
        }
      </div>
    )
  }
}

class ProcessingErrorDisplay extends Component {

  constructor(props){
    super(props)

    this.state= {
      isLoading: false
    }
  }

  handleSubmitButtonClick = () => {
    this.setState({
      isLoading:true
    }, () => {
      this.props.getProcessingErrors(()=>{
        this.setState({isLoading: false})
      })
    })
    
  }

  render(){
    let numberOfDatastreamsWithErrors = Object.keys(this.props.processingErrors).length
    var totalErrors = 0
    for (var key of Object.keys(this.props.processingErrors)) {
        totalErrors += this.props.processingErrors[key].length
    }

    let tooltip = <ProcessingDisplayDatastreamTooltip datastreams={Object.keys(this.props.processingErrors)} />


    return(
      <div className="item-container">
        <p className="item-title">Processing Errors:</p>

        <div className="error-display-datepicker-container">
          <div style={{width:220, display:"inline-block"}}>
            <div style={{width:90, float:"left"}}>
              <DatePicker
                dateFormat= "yyyy-MM-dd"
                selected={this.props.startDate}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                onChange={this.props.handleChangeStart}
                className="error-display-datepicker"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
            <div style={{width:90, display:"inline-block"}}>
              <DatePicker
                dateFormat= "yyyy-MM-dd"
                selected={this.props.endDate}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                onChange={this.props.handleChangeEnd}
                className="error-display-datepicker"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
        </div>
        <div className="error-display-button-container">
          <button disabled={this.state.isLoading} className="error-display-button" onClick={this.handleSubmitButtonClick}>Submit</button>
        </div>
        <div className="error-display-alert-container">
          {this.state.isLoading ?
            <i className="fas fa-2x fa-spinner fa-spin"></i>
          :
            <div>
              <p style={numberOfDatastreamsWithErrors === 0 ? {color:"green"} : {color:"red"}} className="error-display-alert-number">{numberOfDatastreamsWithErrors}</p>
              <div className="error-display-alert-text">
                <Tooltip
                  style={{display:"inlineBlock"}}
                  interactive
                  html = {tooltip}
                  position="bottom"
                  trigger="mouseenter"
                  arrow="true"
                  duration='50'
                  // className="tippy-class"
                  unmountHTMLWhenHide={true}>
                    <p style={{display:"inlineBlock", "fontWeight":"bold",  "cursor":"pointer"}}>datastreams</p>
                  </Tooltip> 
                 {" producing"}</div>
              <br/>
              <p style={totalErrors === 0 ? {color:"green"} : {color:"red"}} className="error-display-alert-number">{totalErrors}</p>
              <p className="error-display-alert-text">unique errors.</p><br/>
              <p className="error-display-alert-text"><p onClick={this.props.openProcessingErrorsDetailsWindow} style={{"textDecoration":"underline", "cursor":"pointer"}}>See More</p></p>

            </div>
          }
        </div>
        <div>
          <p><a target="_blank" href="https://armcrf.servicenowservices.com/$vtb.do?sysparm_board=936fa2b6db67ab40298a3638fc961957">DQO Task Board</a></p>
        </div>
        {this.props.schedulerStatus ?
          <div>
            <p><b>Last DQ Scheduler check for new data:</b></p>
            <p style={this.props.schedulerStatus > 900 ? {color:'red'} : {color:'green'}}>{this.props.schedulerStatus} seconds ago</p>
          </div>
          :
          null
        }
      </div>
    )
  }
}

class DQAWeek extends Component {


  render(){
    return(
      <div className="item-container">
        <p className="item-title">Current DQA Week:</p>
        <p className="dqa-week-text">Start: {this.props.dqaStart}</p>
        <p style={{paddingBottom:16}} className="dqa-week-text">End: {this.props.dqaEnd}</p>
      </div>
    )
  }
}

class DQAProgress extends Component {


  render(){
    let totalAssignments = Object.keys(this.props.dqasComplete).length
    let completedAssignments = 0 
    let percentComplete = 0

    Object.keys(this.props.dqasComplete).forEach(key => {
      if(this.props.dqasComplete[key] === true){
        completedAssignments += 1
      }
    })

    if(totalAssignments > 0){
      percentComplete = Math.round((completedAssignments/totalAssignments) * 100)
    }

    return(
      <div className={"item-container"}>
        <p className={"item-title"}>Current DQA Week Progress:</p>
        <CircularProgressbar
          value={percentComplete}
          text={completedAssignments.toString() + "/" + totalAssignments.toString()}
          initialAnimation={true}
          backgroundPadding={200}
        />
      </div>
    )
  }
}