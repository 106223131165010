import React, { Component } from 'react';

import axios from 'axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import jwt_decode from "jwt-decode";

import Sidebar from "./components/Sidebar";
import HomePage from "./components/HomePage";
import LoginModal from "./components/LoginModal";
import ProgressChart from "./components/ProgressChart";
import AssignmentTable from "./components/AssignmentTable";
import ProcessStatusChart from "./components/ProcessStatusChart";
import ProcessConfigTable from "./components/ProcessConfigTable"; 

import './App.css';

import { PRIMARY_COLOR } from "./constants/constants";

const CancelToken = axios.CancelToken;
let cancel;

// var jwt_decode = require('jwt-decode');

const moment = extendMoment(Moment);

const placeholderUser = {
  name: '',
  role: '',
}

class App extends Component {

  constructor(props){
    super(props)

    this.state = {
      apiBaseURL: '${APP_DQ_API_URL}/',
      currentUser: placeholderUser,
      isAuthenticated: !!localStorage.token,
      isAuthenticating: false,
      authenticationMessage: '',
      viewSelection: 'Home',
      analysts: [],
      assignments: [],
      analystAssignments: [],
      scheduledAssignments: {},
      dqas: {},
      dqasComplete: {},
      isLoadingData: false,
      dqaStart: '',
      dqaEnd: '',
      errorDisplayStartDate: moment().startOf('day').subtract(1, "days").toDate(),
      errorDisplayEndDate: moment().startOf('day').toDate(),
      processingErrors: {},
      dqProcessStatus: {},
      schedulerConf: [],
      schedulerStatus: false,
      amfCodes: {}
    }
  }

  componentDidMount() {
    this.getBaseURL()
    if (!!localStorage.token) {
      let decoded = jwt_decode(localStorage.token)
      let role = decoded.is_manager===true ? "Manager" : "Analyst"
      this.setState({
        currentUser: {
          name: decoded.first_name + ' ' + decoded.last_name,
          role: role,
          id: decoded.id
        }
      }, ()=> {
        this.getDashboardData()
      })
    }
  }

    getBaseURL(){
      let url = window.location.href;

      //Is DEV System
      if (url.indexOf("localhost") >= 0){
        this.setState({
          apiBaseURL: 'http://localhost:7100/',
        })
      }
      //Is Production System
      else{
        this.setState({
          apiBaseURL: '${APP_DQ_API_URL}/',
        })
      }
    }

  getDQAWeek = () => {

    var ts = new Date().getUTCDate() + (6 - new Date().getUTCDay()) - 14 ;
    var dqaStart = new Date();
    dqaStart.setDate(ts);

    var te = new Date().getUTCDate() + (6 - new Date().getUTCDay() - 1) - 7 ;
    var dqaEnd = new Date();
    dqaEnd.setDate(te);


    // let offset = dqaStart.getTimezoneOffset()
    // let sdate = new Date(dqaStart.getTime() + (offset*60*1000))
    // let edate = new Date(dqaEnd.getTime() + (offset*60*1000))

    this.setState({
      dqaStart: dqaStart.toISOString().split('T')[0],
      dqaEnd: dqaEnd.toISOString().split('T')[0]
    })
  }

  getAssignments = (callback) => {
    let query_data = {
      'armid':this.state.currentUser.id
      //'armid': 19048 //Annalisa
    }
    axios.post(this.state.apiBaseURL + 'get_dashboard_data', query_data, {
      cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    })})
    .then(res => {

      this.setState({
        assignments: res.data.assignments,
        analystAssignments: res.data.analyst_assignments,
        scheduledAssignments: res.data.scheduled_assignments,
        analysts: res.data.analysts,
        dqasComplete: res.data.dqas_complete,
        schedulerConf: res.data.scheduler_conf,
        schedulerStatus: res.data.scheduler_status,
        amfCodes: res.data.amfcodes
      }, () => {
        callback()
      })
    })
  }

  getDQAs = (armid, assignments, callback) => {
    let query_data = {
      'armid':armid,
      // 'armid': 19048,
      'assignments': assignments
    }
    axios.post(this.state.apiBaseURL + 'get_dqas', query_data, {
      cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    })})
    .then(res => {
      this.setState({
        dqas: res.data.dqas
      }, () => {
        callback()
      })
    })
  }

  getProcessingErrors = (callback) => {
    let query_data = {
      'start_date': moment(this.state.errorDisplayStartDate).format('YYYYMMDD'),
      'end_date': moment(this.state.errorDisplayEndDate).format('YYYYMMDD')
    }
    axios.post(this.state.apiBaseURL + 'get_processing_errors', query_data, {
      cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    })})
    .then(res => {
      this.setState({
        processingErrors: res.data.errors
      }, () => {
        callback()
      })
    })
  }

  getProcessStatus = (sdate, edate, callback) => {
    // let datastreamsToCheck = []
    // this.state.assignments.forEach(assignment => {
    //   if (assignment.assignment_metadata.deployment_status === "Deployed") {
    //     datastreamsToCheck = datastreamsToCheck.concat(assignment.assignment_metadata.datastreams)
    //   }
    // }) 

    let query_data = {
      'sdate': sdate.replace(/\-/g,""),
      'edate': edate.replace(/\-/g,""),
    }
    axios.post(this.state.apiBaseURL + 'get_scheduler_process_status', query_data, {
      cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    })})
    .then(res => {
     this.setState({
        'dqProcessStatus': res.data
      }, () => {
        callback()
      })
    })
  }

  getProcessStatusById = (processId) => {
    let query_data = {
      'process_id': processId
    }
    axios.post(this.state.apiBaseURL + 'get_scheduler_process_status_by_id', query_data, {
      cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    })})
    .then(res => {
      return res.data
    })
  }

  getDashboardData = () => {
    this.getDQAWeek()
    this.setState({
      isLoadingData: true
    }, () => {
      this.getAssignments( ()=> {

        //Filter out assignments that are not deployed
        let analystAssignments = []
        this.state.assignments.map( (assignment)=> {

          if(assignment.person_id === this.state.currentUser.name.toString()){
            if(assignment.assignment_metadata.deployment_status === "Deployed") {

              let site = assignment.site_code.toLowerCase()
              let inst = assignment.instrument_assignment.toLowerCase()
              if(site.includes('(')){
                site = site.match(/\((.*)\)/)[1]
              }
              analystAssignments.push({
                instrument_assignment: inst,
                site_code: site
              })
            }
          }
        })
        this.getDQAs(this.state.currentUser.id, analystAssignments, () => {
          this.getProcessingErrors(() => {
            this.setState({
              isLoadingData: false
            }, () => {return})
          })
        })
      })
    })
  }

  toggleLoadIndicator = (isLoading) => {
    this.setState({
      isLoadingData: isLoading
    })
  }

  authenticateUser = (username, password) => {
    this.setState({
      isAuthenticating: true
    })
    let url = this.state.apiBaseURL + '/ldap_auth';

    let headers = new Headers();

    //headers.append('Content-Type', 'text/json');
    headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));

    fetch(url, {method:'GET',
      headers: headers,
     })
    .then(response => response.json())
    .then(json => {
      if(json.result === 'success'){
        let decoded = jwt_decode(json.token)
        let role = decoded.is_manager===true ? "Manager" : "Analyst"
        this.setState({
          isAuthenticated: true,
          isAuthenticating: true,
          authenticationMessage: "Authentication Successful",
          currentUser: {
            name: decoded.first_name + ' ' + decoded.last_name,
            role: role,
            id: decoded.id
          }
        }, () => {
          localStorage.setItem('token', json.token)
          this.getDashboardData()

        })
      }
      else if (json.result === 'invalid_credentials'){
        this.setState({
          authenticationMessage: "Invalid Credentials",
          isAuthenticating: false
        })
      }
      else {
        this.setState({
          authenticationMessage: "Authentication Server Error",
          isAuthenticating: false
        })
      }
    });
  }

  handleChangeErrorDisplayStart = (date) => {
    if (date > this.state.errorDisplayEndDate) {
        this.setState({errorDisplayStartDate: date, errorDisplayEndDate: date})
    } else {
        this.setState({errorDisplayStartDate: date})
    }
  }

  handleChangeErrorDisplayEnd = (date) => {
    if (date < this.state.errorDisplayStartDate) {
        this.setState({errorDisplayEndDate: date, errorDisplayStartDate: date})
    } else {
        this.setState({errorDisplayEndDate: date})
    }
  }

  handleViewChange = (selection) => {
    this.setState({
      viewSelection: selection
    })
  }

  updateAssignment = (assignment_id, new_site, new_instrument, new_analyst) => {
    let tempData = this.state.assignments

    for (let i=0; i<tempData.length; i++) {
      if(tempData[i].id === assignment_id){
        tempData[i].person_id = new_analyst
        tempData[i].site_code = new_site
        tempData[i].instrument_assignment = new_instrument
      }
    } 

    this.setState({assignments:tempData})

    if(new_site !== '' && new_instrument !== ''){

      let stripped_site = new_site.split(' ')[0]
      stripped_site = stripped_site.split('(')[0]

      let query_data = {
        'assignment_id':assignment_id,
        'new_site': stripped_site,
        'new_instrument': new_instrument,
        'new_analyst':new_analyst,
      }
      
      axios.post(this.state.apiBaseURL + 'update_instrument_assignment', query_data, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).then(res => {

      })
    }
  }

  addScheduledAssignment = (person_id, site, instrument, date, callback) => {
    let query_data = {
      'person_id':person_id,
      'site': site,
      'instrument':instrument,
      'date':date
    }
    axios.post(this.state.apiBaseURL + 'add_scheduled_instrument_assignment', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      let tempData = this.state.scheduledAssignments

      if (!(site+instrument in tempData)) {
        tempData[site+instrument] = []
      }

      tempData[site+instrument].push({
        'person_id': person_id,
        'date': date
      })

      this.setState({scheduledAssignments: tempData}, () => {
        callback(tempData[site+instrument])
      })
    })
  }

  deleteScheduledAssignment = (person_id, site, instrument, date, callback) => {
    let query_data = {
      'person_id':person_id,
      'site': site,
      'instrument':instrument,
      'date':date
    }
    axios.post(this.state.apiBaseURL + 'delete_scheduled_instrument_assignment', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      let tempData = this.state.scheduledAssignments

      for(let i=0; i<tempData[site+instrument].length; i++){

        if(tempData[site+instrument][i].person_id === person_id && tempData[site+instrument][i].date === date){
          tempData[site+instrument].splice(i, 1);
          break;
        }
      }

      if (tempData[site+instrument].length === 0) {
        delete tempData[site+instrument]
      }

      this.setState({scheduledAssignments: tempData}, () => {
        callback(tempData[site+instrument])
      })
    })
  }

  updateAssignmentMetadata = (key, assignment_id, newMetadata) => {

    let query_data = {
      'assignment_id':assignment_id,
      'key': key,
      'new_metadata':newMetadata
    }
    axios.post(this.state.apiBaseURL + 'update_instrument_assignment_metadata', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      let tempData = this.state.assignments
      for(let i=0; i<tempData.length; i++){
        if(tempData[i].id === assignment_id){
          if(tempData[i].assignment_metadata === null){
            tempData[i].assignment_metadata = {}
          }
          tempData[i].assignment_metadata[key] = newMetadata
          break;
        }
      }
      this.setState({assignments: tempData})
    })
  }

  addAssignment = (site, instrument, analyst) => {
    let query_data = {
      'site': site,
      'instrument':instrument,
      'analyst': analyst,
    }

    let assignmentExists = false
    this.state.assignments.forEach(assignment => {
      if(assignment.site_code === site && assignment.instrument_assignment === instrument){
        assignmentExists = true
      }
    })

    if(assignmentExists){
      alert("An assignment already exists for " + site + instrument)
    } else {
      axios.post(this.state.apiBaseURL + 'add_instrument_assignment', query_data, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).then(res => {
        let tempData = this.state.assignments

        tempData.push({
          id: res.data.assignment_id,
          person_id: analyst,
          site_code: site,
          instrument_assignment: instrument,
          comment: '',
          assignment_metadata: {
            datastreams: [],
            deployment_status: "Deployed"
          }
        })
        this.setState({assignments: tempData})
      })
    }
  }

  deleteAssignment = (assignment_id) => {
    let query_data = {
      'assignment_id':assignment_id,
    }
    axios.post(this.state.apiBaseURL + 'delete_instrument_assignment', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      let tempData = this.state.assignments
      for(let i=0; i<tempData.length; i++){
        if(tempData[i].id === assignment_id){
          tempData.splice(i, 1);
          break;
        }
      }
      this.setState({assignments:tempData})
    })
  }

  toggleProcessingEnabled = (ds_conf_obj, callback) => {
    axios.post(this.state.apiBaseURL + 'toggle_datastream_conf', ds_conf_obj, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {

      if (res.data.message === 'Success') {
        let tempData = this.state.schedulerConf

       for(let i=0; i<tempData.length; i++) {
          let currConf = tempData[i]

          if (currConf['datastream'] === ds_conf_obj['datastream']) {
            tempData[i]['processing_enabled'] = !ds_conf_obj['processing_enabled']
            this.setState({schedulerConf: tempData})
            break
          }
        }
      }

      callback(res.data.message)
    })
  }

  addDatastreamConf = (ds_conf_obj, callback) => {
    axios.post(this.state.apiBaseURL + 'add_datastream_conf', ds_conf_obj, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {

      if (res.data.message === 'Success') {
        let tempData = this.state.schedulerConf

        tempData.push(ds_conf_obj)
        this.setState({schedulerConf: tempData})
      }

      callback(res.data.message)
    })
  }

  deleteDatastreamConf = (ds_conf_obj, callback) => {
    axios.post(this.state.apiBaseURL + 'delete_datastream_conf', ds_conf_obj, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {

      if (res.data.message === 'Success') {
        let tempData = this.state.schedulerConf
        let spliceIndex = false
        for(let i=0; i<tempData.length; i++) {
          let currConf = tempData[i]

          if (currConf['datastream'] === ds_conf_obj['datastream']) {
            spliceIndex = i
            break
          }
        }

        if(spliceIndex !== false) {
          tempData.splice(spliceIndex, 1);
        }

        this.setState({schedulerConf: tempData})
      }

      callback(res.data.message)
    })
  }

  modifyDatastreamConf = (column_key, ds_conf_obj, new_value, callback) => {
    let query_data = {"column_key": column_key, "ds_conf": ds_conf_obj, "new_value": new_value}
    axios.post(this.state.apiBaseURL + 'modify_datastream_conf', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {

      if (res.data.message === 'Success') {
        let tempData = this.state.schedulerConf

       for(let i=0; i<tempData.length; i++) {
          let currConf = tempData[i]

          if (currConf['datastream'] === ds_conf_obj['datastream']) {

            tempData[i][column_key] = new_value
            this.setState({schedulerConf: tempData})
            break
          }
        }
      }

      callback(res.data.message)
    })

  }



  render() {
    return (
      <div className="App">

      {!this.state.isAuthenticated ? 
        <LoginModal
          authenticateUser={this.authenticateUser}
          isAuthenticating={this.state.isAuthenticating}
          message={this.state.authenticationMessage}
        />
      :
        <div style={{width: '100%', height:'100%'}}>
          <div style={this.state.isLoadingData ? {display:'block'} : {display:'none'}} className='load-overlay'>
          </div>
          <div style={this.state.isLoadingData ? {display:'block'} : {display:'none'}} className='centered-div'>
            <p>Loading...</p>
            <i className="fas fa-2x fa-spinner load-icon fa-spin"></i>
          </div>
          <Sidebar
            background={PRIMARY_COLOR}
            user={this.state.currentUser}
            handleViewChange={this.handleViewChange}
            viewSelection={this.state.viewSelection}
          >
          </Sidebar>
          <div className='main-content'>
            <HomePage 
                visible={this.state.viewSelection === 'Home'} 
                handleChangeErrorDisplayStart={this.handleChangeErrorDisplayStart}
                handleChangeErrorDisplayEnd={this.handleChangeErrorDisplayEnd}
                errorDisplayStartDate={this.state.errorDisplayStartDate} 
                errorDisplayEndDate={this.state.errorDisplayEndDate} 
                getProcessingErrors={this.getProcessingErrors} 
                processingErrors={this.state.processingErrors}
                dqaStart={this.state.dqaStart} 
                dqaEnd={this.state.dqaEnd} 
                dqasComplete={this.state.dqasComplete}
                schedulerStatus={this.state.schedulerStatus}
            />
            <AssignmentTable 
                visible={this.state.viewSelection === 'Assignments'} 
                editable={this.state.currentUser.role === "Manager"} 
                updateAssignmentMetadata={this.updateAssignmentMetadata} 
                addAssignment={this.addAssignment} 
                updateAssignment={this.updateAssignment} 
                deleteAssignment={this.deleteAssignment} 
                analysts={this.state.analysts} 
                data={this.state.assignments}
                scheduledAssignments={this.state.scheduledAssignments}
                addScheduledAssignment={this.addScheduledAssignment}
                deleteScheduledAssignment={this.deleteScheduledAssignment}
            /> 
            <ProgressChart 
                visible={this.state.viewSelection === 'DQAs'} 
                toggleLoadIndicator={this.toggleLoadIndicator} 
                getDQAs={this.getDQAs} 
                currentUser={this.state.currentUser} 
                data={this.state.dqas} 
                assignments={this.state.assignments} 
                analysts={this.state.analysts}
                amfCodes={this.state.amfCodes}
            />
            <ProcessStatusChart 
                apiBaseURL={this.state.apiBaseURL}
                visible={this.state.viewSelection === 'Process Status'} 
                toggleLoadIndicator={this.toggleLoadIndicator} 
                getProcessStatusById={this.getProcessStatusById} 
                getProcessStatus={this.getProcessStatus} 
                currentUser={this.state.currentUser} 
                data={this.state.dqProcessStatus} 
                dqaStart={this.state.dqaStart} 
                dqaEnd={this.state.dqaEnd}
            />
            <ProcessConfigTable
                apiBaseURL={this.state.apiBaseURL}
                visible={this.state.viewSelection === 'Process Config'}
                schedulerConf={this.state.schedulerConf} 
                amfCodes={this.state.amfCodes}
                addDatastreamConf={this.addDatastreamConf}
                deleteDatastreamConf={this.deleteDatastreamConf}
                toggleProcessingEnabled={this.toggleProcessingEnabled}
                modifyDatastreamConf={this.modifyDatastreamConf}
            />
          </div>
        </div>
      }

      </div>
    );
  }
}

export default App;
