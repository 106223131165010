import React, { Component } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faList, faCalendarCheck, faExclamationTriangle, faCog} from '@fortawesome/free-solid-svg-icons'

import SidebarProfile from "./SidebarProfile.js";

library.add(faHome, faList, faCalendarCheck, faExclamationTriangle, faCog)

export default class Sidebar extends Component {

	handleClick = (buttonName) => {
		console.log(buttonName)
		this.props.handleViewChange(buttonName)
	}

	render() {
		return(
			<div className='sidebar' style={{background:this.props.background}}>
				<SidebarProfile
					user={this.props.user}
				/>
				<SidebarButton
					onClick={() => { this.handleClick('Home') } }
					label={'Home'}
					isSelected={this.props.viewSelection === 'Home'}
					icon={'home'}
				/>
				<SidebarButton
					onClick={() => this.handleClick('Assignments')}
					label={'Assignments'}
					isSelected={this.props.viewSelection === 'Assignments'}
					icon={'list'}
				/>
				<SidebarButton
					onClick={() => this.handleClick('DQAs')}
					label={'DQA Timeline'}
					isSelected={this.props.viewSelection === 'DQAs'}
					icon={'calendar-check'}
				/>
				<SidebarButton
					onClick={() => this.handleClick('Process Status')}
					label={'Process Status'}
					isSelected={this.props.viewSelection === 'Process Status'}
					icon={'exclamation-triangle'}
				/>
				{this.props.user.role === "Manager" ?
					<SidebarButton
						onClick={() => this.handleClick('Process Config')}
						label={'Process Config'}
						isSelected={this.props.viewSelection === 'Process Config'}
						icon={'cog'}
					/>
				:
					null
				}
				
			</div>
		)
	}
	
}


class SidebarButton extends Component {
	render() {
		return(
			<div onClick={this.props.onClick} className='sidebar-button-container'>
				{this.props.isSelected ? <div className='sidebar-button-selection-indicator'></div> : null}
				<div className='sidebar-button'>
					<FontAwesomeIcon className='sidebar-button-icon' icon={this.props.icon}  />
					<p className='sidebar-button-label noselect'><strong>{this.props.label}</strong></p>
				</div>
			</div>
		)
	}
}